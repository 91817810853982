import { AxiosInstance } from 'axios';
import { setFreshAccessTokenToStorage } from '@function/auth/token';
import { decryptUserInfo } from '@function/auth/encryption';
import { AuthResponse, LoginAuth, RefreshTokenAuth } from '@interfaces/auth';
import { UserInfoType } from '@interfaces/userInfo';
import Authorization from '@function/auth/authorization';
import client from '@api/client';
import tokenStorage from '@function/auth/tokenStorage';
import { shareLogin } from '@function/shareLogin';

// NOTE: auth가 아닌 client header에 authorization을 할당해야한다
const clientHeader = new Authorization(client);

class AuthUser {
  instance: AxiosInstance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // NOTE: 로그인이 mounted 될 때, 서버를 깨움 (데이터를 빨리 불러올수있음)
  wakeUpServer(): Promise<void> {
    return this.instance.options('/warmup');
  }

  fetchRefreshToken({ refreshToken }: RefreshTokenAuth): Promise<AuthResponse> {
    return this.instance.post('/oauth/token', {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });
  }

  loginServer({ username, password }: LoginAuth): Promise<AuthResponse> {
    return this.instance.post('/oauth/token', {
      grant_type: 'password',
      username,
      password,
    });
  }

  // NOTE: 로그인 로직 | refresh token 로직
  // 받아온 token을 userInfo를 해석해서 return
  async login({ username, password }: LoginAuth): Promise<UserInfoType | null> {
    const { data } = await this.loginServer({
      username,
      password,
    });

    const newToken = setFreshAccessTokenToStorage(data);
    if (!newToken) throw new Error('로그인 중 토큰 가져올 수 없음');

    clientHeader.setAuth(newToken.access_token);
    return decryptUserInfo(newToken);
    // TODO: google tag manger 설정해야함
    // set gtm variables fot user tracking
  }

  logout(): void {
    clientHeader.clearAuth();
    tokenStorage.remove();
    shareLogin.clearCookie();
  }
}

export default AuthUser;
