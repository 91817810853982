import CryptoJS from 'crypto-js';
import cookies from 'js-cookie';
import CONFIG from '@config/';
import COOKIES from '@data/cookie';

const cookieApi = cookies.withAttributes({ path: '/', domain: CONFIG.DOMAIN, secure: true });

class ShareUserInfo {
  secret: string | undefined;
  isStagingServer: boolean;
  cookieKey: string;
  constructor(secret: string | undefined, userKey: string) {
    this.secret = secret;
    this.isStagingServer = CONFIG.MODE === 'staging';
    this.cookieKey = `${this.isStagingServer ? COOKIES.STAGING_PREFIX : ''}${userKey}`;
  }

  encryptUserInfo = (info: string): string => {
    if (!this.secret) return '';
    return CryptoJS.AES.encrypt(info, this.secret).toString();
  };

  decryptUserInfo(encryptedInfo: string): string {
    if (!this.secret) return '';
    return CryptoJS.AES.decrypt(encryptedInfo, this.secret).toString(CryptoJS.enc.Utf8);
  }

  clearCookie(): void {
    cookieApi.remove(this.cookieKey);
  }

  ready = (email: string, password: string): void => {
    const userInfo = `${email} ${password}`;
    const encryptedUserInfo = this.encryptUserInfo(userInfo);

    cookieApi.set(this.cookieKey, encryptedUserInfo);
  };

  /** pc version에서 저장된 UserInfo 를 가져옴 */
  getUserInfoFromPCVersion(): string {
    const userInfo = cookieApi.get(this.cookieKey);
    if (!userInfo) return '';

    this.clearCookie();
    return this.decryptUserInfo(userInfo);
  }
}

export const shareLogin = new ShareUserInfo(CONFIG.KEYS.USER_SECRET, COOKIES.USER);
