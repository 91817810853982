import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useQueryClient } from 'react-query';
import { CloseIcon, IconButton } from 'loplat-ui';
import { StyledButton } from '@F/StyledButton/styles';
import campaignsParams from '@recoil/campaignsParams.atom';
import { MonthlyStatus } from '@interfaces/campaigns/api';
import { CampaignFilter } from '@interfaces/filter';
import { getMonthlyTotalByStatus } from '@function/campaign/getTotalCount';
import FILTER from '@data/filterData';
import * as S from '../styles';

interface Props {
  closeModal: () => void;
}

const FilterModal = ({ closeModal }: Props): React.ReactElement => {
  const [params, setParams] = useRecoilState(campaignsParams);
  const { status } = params;

  const queryClient = useQueryClient();
  const monthlyStatus: MonthlyStatus | undefined =
    queryClient.getQueryData('campaignsMonthlyStatus');

  const filterCampaignsByStatus = useCallback(
    (status: CampaignFilter['eng']) => {
      setParams((prev) => ({
        ...prev,
        page: 1,
        status,
      }));
      closeModal();
    },
    [setParams, closeModal]
  );

  return (
    <>
      <S.ModalTitle>
        <p>캠페인 필터</p>
        <IconButton onClick={closeModal} color='ghost'>
          <CloseIcon size={16} />
        </IconButton>
      </S.ModalTitle>

      <S.ModalBody>
        <S.ButtonListWrapper>
          {(Object.entries(FILTER.TO_KOREAN) as Entries<typeof FILTER.TO_KOREAN>).map(
            ([statusEnglish, statusKorean]) => (
              <StyledButton
                key={statusEnglish}
                onClick={() => filterCampaignsByStatus(statusEnglish)}
                focused={statusEnglish === status}
                fullWidth
              >
                {statusKorean}(
                {getMonthlyTotalByStatus(statusEnglish, monthlyStatus).toLocaleString()})
              </StyledButton>
            )
          )}
        </S.ButtonListWrapper>
      </S.ModalBody>
    </>
  );
};

export default FilterModal;
