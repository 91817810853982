import axios from 'axios';
import CONFIG from '@config/index';

const authClient = axios.create({
  baseURL: CONFIG.AUTH_API_URL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
    'Content-Type': 'application/json',
  },
});

export default authClient;
