import CryptoJS from 'crypto-js';
import CONFIG from '@config/';
import TokenTypes, { DecryptedAccessToken } from '@interfaces/token';
import jwtDecode from 'jwt-decode';

export const encryptRefreshToken = (token: TokenTypes): string => {
  if (!CONFIG.KEYS.SECRET) return '';
  return CryptoJS.AES.encrypt(token.refresh_token, CONFIG.KEYS.SECRET).toString();
};

export const decryptRefreshToken = (token: TokenTypes): string => {
  if (!CONFIG.KEYS.SECRET) return '';
  return CryptoJS.AES.decrypt(token.refresh_token, CONFIG.KEYS.SECRET).toString(CryptoJS.enc.Utf8);
};

export const decryptUserInfo = (token: TokenTypes) => {
  const accessToken: DecryptedAccessToken = jwtDecode(token.access_token);

  return Object.assign(accessToken.user_claims, {
    identity: accessToken.identity,
    scope: accessToken.scope.split(' '),
    id: accessToken.sub,
  });
};
