import { MonthlyStatus } from '@interfaces/campaigns/api';
import { CampaignFilter } from '@interfaces/filter';
import FILTER_DATA from '@data/filterData';

export const getMonthlyTotalByStatus = (
  status: CampaignFilter['eng'],
  monthlyStatus: MonthlyStatus | undefined
): number => {
  if (!monthlyStatus) return 0;
  if (status === 'all')
    return monthlyStatus.end + monthlyStatus.pending + monthlyStatus.active + monthlyStatus.test;
  if (status === 'proceeding') return monthlyStatus.active + monthlyStatus.test;
  return monthlyStatus[FILTER_DATA.TO_MONTHLY_STATUS[status]];
};
