import { CampaignStatusKorean } from '@interfaces/campaigns';
import { Campaign } from '@interfaces/campaigns/api';

const STATUS_BY_NUMBER: Record<Campaign['status'], Exclude<CampaignStatusKorean, '테스트'>> = {
  0: '대기',
  1: '진행',
  2: '진행',
  7: '종료',
};

const CHANGE_STATUS: Record<
  Extract<CampaignStatusKorean, '대기' | '진행'>,
  Extract<Campaign['status'], 0 | 1>
> = { 대기: 0, 진행: 1 };

export default {
  MESSAGE: {
    EXCESS_GEOFENCE_LIMIT: `활성 지오펜스가 한도 초과하여 \n 캠페인을 진행할 수 없습니다.`,
    NO_CAMPAIGN: '등록된 캠페인이 없습니다.',
    RETRY_FETCH_CAMPAIGN: `캠페인 목록을 불러 올 수 없습니다. \n 새로고침 후 다시 시도해주세요.`,
  },
  STATUS_BY_NUMBER,
  CHANGE_STATUS,
};
