import { useEffect, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import userInfo from '@recoil/userInfo.atoms';
import { AuthUserApi } from '@api/index';
import { AccessibleByType } from '@interfaces/routesType';
import { LoginAuth } from '@interfaces/auth';
import { shareLogin } from '@function/shareLogin';

type WithAuthProps = {
  accessibleBy: AccessibleByType;
  children: JSX.Element;
};

const WithAuth = ({ children, accessibleBy }: WithAuthProps): JSX.Element => {
  const [auth, setAuth] = useRecoilState(userInfo);
  const navigate = useNavigate();

  const autoLoginFromPC = useCallback(
    async (userFromPC: string) => {
      const userInfo = userFromPC.split(' ');
      const loginRequest: LoginAuth = {
        username: userInfo[0],
        password: userInfo[1],
      };

      const userAuth = await AuthUserApi.login(loginRequest);
      setAuth(userAuth);
    },
    [setAuth]
  );

  useEffect(() => {
    if (!auth?.id) {
      const userFromPC = shareLogin.getUserInfoFromPCVersion();
      if (userFromPC) autoLoginFromPC(userFromPC);
      else if (accessibleBy === 'authorized') {
        return navigate('/login');
      }
    } else if (accessibleBy === 'unauthorized') {
      return navigate('/');
    }
  }, [accessibleBy, auth?.id, autoLoginFromPC, navigate]);

  return children;
};

export default WithAuth;
