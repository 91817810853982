import { CampaignStatusEnglish } from '@interfaces/campaigns';
import { GetParams } from '@interfaces/campaigns/api';

const CAMPAIGN_STATUS: CampaignStatusEnglish[] = ['complete', 'test', 'active', 'inactive'];
const stringifyStatus = (string: string): string =>
  CAMPAIGN_STATUS.filter((item) => item === string).join(',');

export const defineStatus = (status: GetParams['status']): string => {
  if (status === 'all') return CAMPAIGN_STATUS.join(',');
  if (status === 'proceeding')
    return CAMPAIGN_STATUS.filter((item) => item === 'test' || item === 'active').join(',');

  return stringifyStatus(status);
};
