import { AxiosInstance } from 'axios';
import { GeofenceStatusResponseType } from '@interfaces/geofence';

class Geofence {
  instance: AxiosInstance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(): Promise<GeofenceStatusResponseType> {
    return this.instance.get('/geofence/state');
  }
}

export default Geofence;
