import React, { useCallback } from 'react';
import userInfo from '@recoil/userInfo.atoms';
import { useSetRecoilState } from 'recoil';
import { Button } from 'loplat-ui';
import { AuthUserApi } from '@api/index';
import * as S from '../styles';

export interface Props {
  closeModal: () => void;
}

const LogOutModal = ({ closeModal }: Props): React.ReactElement => {
  const setUser = useSetRecoilState(userInfo);

  const onLogOutYes = useCallback(() => {
    closeModal();
    setUser(null);
    AuthUserApi.logout();
  }, [closeModal, setUser]);

  const onLogOutNo = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <>
      <S.ModalBody>
        <p>정말 로그아웃 하시겠습니까?</p>
      </S.ModalBody>

      <S.Footer>
        <Button color='ghost' onClick={onLogOutYes}>
          예
        </Button>
        <Button color='ghost' onClick={onLogOutNo}>
          아니오
        </Button>
      </S.Footer>
    </>
  );
};

export default LogOutModal;
