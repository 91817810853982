import React, { Suspense } from 'react';
import { CubeSpinner } from 'loplat-ui';
import { Routes, Route } from 'react-router-dom';
import routes from './routes';
import WithAuth from './WithAuth';

const Router = (): React.ReactElement => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
          }}
        >
          <CubeSpinner scale={0.6} />
        </div>
      }
    >
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <WithAuth accessibleBy={route.accessibleBy}>
                <route.element />
              </WithAuth>
            }
          />
        ))}
      </Routes>
    </Suspense>
  );
};

export default Router;
