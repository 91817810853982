import { MonthlyStatus } from '@interfaces/campaigns/api';
import { CampaignFilter } from '@interfaces/filter';

const TO_KOREAN: Record<CampaignFilter['eng'], CampaignFilter['kor']> = {
  all: '전체',
  proceeding: '진행',
  inactive: '대기',
  complete: '종료',
};

const TO_MONTHLY_STATUS: Record<
  Extract<CampaignFilter['eng'], 'inactive' | 'complete'>,
  keyof MonthlyStatus
> = {
  inactive: 'pending',
  complete: 'end',
};

export default {
  TO_KOREAN,
  TO_MONTHLY_STATUS,
};
