import { AxiosInstance } from 'axios';

class Authorization {
  instance: AxiosInstance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  setAuth(accessToken: string): void {
    this.instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  clearAuth(): void {
    this.instance.defaults.headers.common.Authorization = '';
  }
}

export default Authorization;
