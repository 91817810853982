import dayjs from 'dayjs';
import { encryptRefreshToken } from '@function/auth/encryption';
import { AuthUserApi } from '@api/index';
import TokenTypes from '@interfaces/token';
import tokenStorage from './tokenStorage';
import { decryptRefreshToken } from './encryption';

// NOTE: 암호화한 토큰을 storage에 저장
export const setFreshAccessTokenToStorage = (token: TokenTypes): TokenTypes => {
  const refreshToken = encryptRefreshToken(token);
  if (!refreshToken) throw new Error('config에 secret키가 없습니다.');

  const newToken = {
    ...token,
    lastUpdatedAt: String(dayjs().unix()),
    refresh_token: refreshToken,
  };

  tokenStorage.set(newToken);
  return newToken;
};

const fetchNewToken = async (refreshToken: string) => {
  return await AuthUserApi.fetchRefreshToken({
    refreshToken,
  });
};

// NOTE: 오래된 토큰을 해석하여 새토큰을 받아온 뒤, storage에 저장
export const refreshOldToken = async (oldToken: TokenTypes): Promise<TokenTypes | undefined> => {
  const decryptedRefreshToken = decryptRefreshToken(oldToken);

  if (decryptedRefreshToken) {
    const newToken = await fetchNewToken(decryptedRefreshToken);
    return setFreshAccessTokenToStorage({
      access_token: newToken.data.access_token,
      refresh_token: decryptedRefreshToken,
    });
  }
};
