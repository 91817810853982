import cookies from 'js-cookie';
import AUTH_DATA from '@data/auth';
import CONFIG from '@config/';
import COOKIES from '@data/cookie';
import TokenTypes from '@interfaces/token';
import dayjs from 'dayjs';

const cookieApi = cookies.withAttributes({ path: '/', domain: CONFIG.DOMAIN, secure: true });

class StorageFunctions {
  isStagingServer: boolean;
  cookieKey: string;
  constructor(key: string) {
    this.isStagingServer = CONFIG.MODE === 'staging';
    this.cookieKey = `${this.isStagingServer ? COOKIES.STAGING_PREFIX : ''}${key}`;
  }

  get() {
    const stringifiedToken = cookieApi.get(this.cookieKey);
    return stringifiedToken !== undefined ? JSON.parse(stringifiedToken) : null;
  }
  remove() {
    cookieApi.remove(this.cookieKey);
  }
  set(newToken: TokenTypes) {
    cookieApi.set(this.cookieKey, JSON.stringify(newToken), {
      expires: dayjs().add(AUTH_DATA.TOKEN_EXPIRATION_TIME, 'second').toDate(),
    });
  }
}

const tokenStorage = new StorageFunctions(COOKIES.TOKEN);

export default tokenStorage;
