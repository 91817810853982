import React, { useCallback } from 'react';
import { Button, WarningCircleOutlineIcon, blue500 } from 'loplat-ui';
import { preventRedirect } from '@function/redirection';
import * as S from '../styles';
import { Body, WarningText } from './styles';
import CONFIG from '@config/';

export interface Props {
  closeModal: () => void;
}

const ChangeVersion = ({ closeModal }: Props): React.ReactElement => {
  const changeViewVersion = useCallback(() => {
    preventRedirect();
    closeModal();

    const pcVersionUrl =
      CONFIG.MODE === 'localhost'
        ? 'http://localhost:8081'
        : CONFIG.MODE === 'staging'
        ? 'https://staging.x.loplat.com'
        : 'https://x.loplat.com';

    window.location.href = pcVersionUrl;
  }, [closeModal]);

  return (
    <>
      <Body>
        <WarningCircleOutlineIcon fillColor={blue500} size={32} suffixForId='warningIcon' />
        <WarningText>
          <strong>모바일에서 PC버전 이용 시 기능에 제한이 있을 수 있습니다.</strong>
          <br />
          계속 이용하시겠습니까?
        </WarningText>
      </Body>
      <S.Footer>
        <Button color='ghost' onClick={changeViewVersion}>
          예
        </Button>
        <Button color='ghost' onClick={closeModal}>
          아니오
        </Button>
      </S.Footer>
    </>
  );
};

export default ChangeVersion;
