/* eslint-disable class-methods-use-this */
import { AxiosInstance } from 'axios';
import {
  UpdateStatusParams,
  GetParams,
  FetchCampaigns,
  MonthlyStatus,
} from '@interfaces/campaigns/api';
import { defineStatus } from '@function/campaign/getStatus';

class Campaign {
  instance: AxiosInstance;
  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  wakeUpServer(): Promise<void> {
    return this.instance.options('/');
  }

  async get({ page, size, status, orderby }: GetParams): Promise<{ data: FetchCampaigns }> {
    const stringifiedStatus = defineStatus(status);

    return this.instance.get('/campaign', {
      params: { page, size, status: stringifiedStatus, orderby },
    });
  }

  /** NOTE: trigger.nearby 를 바꿔야할때 0 | 1 로  전달할 것 */
  patch({ campaignId, data }: UpdateStatusParams): Promise<Campaign> {
    return this.instance.patch(`/campaign/${campaignId}`, data);
  }

  getMonthlyStatus(): Promise<{ data: { monthly_stat: MonthlyStatus } }> {
    return this.instance.get('/campaign/monthly_stat');
  }
}

export default Campaign;
