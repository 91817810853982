export default {
  MESSAGES: {
    WRONG_EMAIL: '올바른 이메일 형식이 아닙니다.',
    SUCCESS_LOGIN: '로그인되었습니다.',
    INVALID_LOGIN: '이메일 또는 패스워드를 다시 확인해주세요.',
    ANCIENT_LOGIN: 'loplat X에 접속하신 시간이 2시간 이상 되어, 사이트를 새로고침 하셔야 합니다.',
  },
  ROLES: ['user', 'manager', 'admin'] as const,
  REFRESH_DELAY: 600, // one hour
  TOKEN_EXPIRATION_TIME: 3600, // one hour in second
  MEMBERSHIP: ['LITE', 'BASIC', 'PREMIUM'] as const,
};
