import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import modalState from '@recoil/modal.atoms';
import Modal from '@F/Modal';
import LogOutModal from './LogOutModal';
import FilterModal from './FilterModal';
import StatusModal from './StatusModal';
import ChangeVersionModal from './ChangeVersionModal';

const CustomModal = (): React.ReactElement => {
  const modalStatus = useRecoilValue(modalState);
  const closeModal = useResetRecoilState(modalState);

  return (
    <Modal>
      <>
        {modalStatus.type === 'logout' && <LogOutModal closeModal={closeModal} />}
        {modalStatus.type === 'filter' && <FilterModal closeModal={closeModal} />}
        {modalStatus.type === 'status' && <StatusModal closeModal={closeModal} />}
        {modalStatus.type === 'version' && <ChangeVersionModal closeModal={closeModal} />}
      </>
    </Modal>
  );
};

export default CustomModal;
