import axios from 'axios';
import dayjs from 'dayjs';
import CONFIG from '@config/';
import AUTH_DATA from '@data/auth';
import AxiosAuthConfig from '@interfaces/axios';
import Authorization from '@function/auth/authorization';
import { refreshOldToken } from '../function/auth/token';
import tokenStorage from '@function/auth/tokenStorage';

const client = axios.create({
  baseURL: CONFIG.API_URL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept',
    'Content-type': 'application/json',
  },
});

const clientHeader = new Authorization(client);

// request
// NOTE: 스토리지에 token 있는지 확인해서 fresh일 때 request 헤더에 설정함
// clientHeader.setAuth(...)를 사용하지않고 request에 바로 Authorization을 설정해야함
client.interceptors.request.use(
  async (request: AxiosAuthConfig) => {
    const token = tokenStorage.get();
    if (!token) return request;

    const isFreshToken = dayjs().unix() - Number(token.lastUpdatedAt) < AUTH_DATA.REFRESH_DELAY;
    if (isFreshToken && request.headers)
      request.headers.Authorization = `Bearer ${token.access_token}`;

    if (!isFreshToken && request.headers) {
      const newToken = await refreshOldToken(token);
      if (newToken) request.headers.Authorization = `Bearer ${token.access_token}`;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

// response
// NOTE: 401일때 토큰을 재발행 후 헤더에 재설정하거나 로그인페이지로 리다이렉트
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status !== 401) return Promise.reject(error);

    try {
      const token = tokenStorage.get();
      if (!token) return;

      const newToken = await refreshOldToken(token);
      if (newToken) return clientHeader.setAuth(newToken.access_token);
    } catch {
      clientHeader.clearAuth();
      tokenStorage.remove();
    }

    // TODO: 오래된 로그인 알려야 함
    // toast.danger(auth.messages.ancient_login)

    return Promise.reject(error);
  }
);

export default client;
