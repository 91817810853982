import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CloseIcon, toast, IconButton } from 'loplat-ui';
import { StyledButton } from '@F/StyledButton/styles';
import { CampaignApi } from '@api/index';
import CAMPAIGN_DATA from '@data/campaigns';
import { HTTPError } from '@interfaces/HttpError';
import { UpdateStatusParams } from '@interfaces/campaigns/api';
import fetchingCampaignLoading from '@recoil/fetchingCampaignLoading.atom';
import campaignToChangeInfo from '@recoil/campaignToChangeInfo.atom';
import * as S from '../styles';

interface Props {
  closeModal: () => void;
}

const StatusModal = ({ closeModal }: Props): React.ReactElement => {
  const setIsLoading = useSetRecoilState(fetchingCampaignLoading);
  const campaignToChange = useRecoilValue(campaignToChangeInfo);
  const queryClient = useQueryClient();

  const mutateCampaignStatus = useMutation(
    (data: UpdateStatusParams) => {
      return CampaignApi.patch(data);
    },
    {
      onSettled: () => {
        queryClient.refetchQueries({ stale: true });
        setIsLoading(false);
      },
      onError: (error) => {
        // NOTE: useMutation의 error타입이 unknown으로 정해져 있어
        // 타입가드를 대체하기 위한 손쉬운 방법으로 타입단언을 사용함
        const httpError = error as HTTPError;
        if (httpError.response?.data.error === 'NotAllowedError') {
          toast.danger(CAMPAIGN_DATA.MESSAGE.EXCESS_GEOFENCE_LIMIT);
        } else {
          toast.danger(CAMPAIGN_DATA.MESSAGE.RETRY_FETCH_CAMPAIGN);
        }
        mutateCampaignStatus.reset();
      },
      onMutate: () => {
        setIsLoading(true);
      },
    }
  );

  const changeCampaignStatus = useCallback(
    (statusNumber: UpdateStatusParams['data']['status']) => {
      if (!campaignToChange) return;

      let changeNumber = statusNumber;
      if (campaignToChange.isTestCampaign) changeNumber = statusNumber === 1 ? 2 : statusNumber;

      const paramsData: UpdateStatusParams = {
        campaignId: campaignToChange.campaignId,
        data: {
          status: changeNumber,
        },
      };
      mutateCampaignStatus.mutate(paramsData);
      closeModal();
    },
    [campaignToChange, closeModal, mutateCampaignStatus]
  );

  return (
    <>
      <S.ModalTitle>
        <p>
          <strong>{campaignToChange?.campaignName}</strong> 캠페인 상태 변경하기
        </p>
        <IconButton onClick={closeModal} color='ghost' aria-label='캠페인 상태변경 취소'>
          <CloseIcon size={16} />
        </IconButton>
      </S.ModalTitle>

      <S.ModalBody>
        <S.ButtonListWrapper>
          {Object.entries(CAMPAIGN_DATA.CHANGE_STATUS)
            .filter((status): status is Entry<typeof CAMPAIGN_DATA.CHANGE_STATUS> => true)
            .filter(([status]) => status !== campaignToChange?.status)
            .map(([status, statusNumber]) => (
              <StyledButton
                key={status}
                onClick={() => changeCampaignStatus(statusNumber)}
                fullWidth
              >
                {status}
              </StyledButton>
            ))}
        </S.ButtonListWrapper>
      </S.ModalBody>
    </>
  );
};

export default StatusModal;
