export default {
  API_URL: process.env.REACT_APP_API_URL,
  AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL,

  KEYS: {
    SECRET: process.env.REACT_APP_SECRET_KEY,
    USER_SECRET: process.env.REACT_APP_USER_SECRET_KEY,
  },

  // NOTE: bitbucket Deployments 에서 설정함.
  // staging | production | localhost
  MODE: process.env.REACT_APP_MODE ?? 'localhost',
  DOMAIN: (process.env.REACT_APP_MODE ?? 'localhost') === 'localhost' ? 'localhost' : '.loplat.com',
};
