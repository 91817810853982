import { atom } from 'recoil';

interface Modal {
  isOpen: boolean;
  type: 'filter' | 'status' | 'logout' | 'version' | null;
}

const modalState = atom<Modal>({
  key: 'modalState',
  default: { isOpen: false, type: null },
});

export default modalState;
