import cookies from 'js-cookie';
import dayjs from 'dayjs';
import COOKIES from '@data/cookie';
import CONFIG from '@config/';

const REDIRECT_VALID_STRING = 'from__m.x.loplat';
const REDIRECT_KEY = `${CONFIG.MODE === 'staging' ? COOKIES.STAGING_PREFIX : ''}${
  COOKIES.REDIRECT
}`;
export const preventRedirect = (): void => {
  cookies.set(REDIRECT_KEY, REDIRECT_VALID_STRING, {
    domain: CONFIG.DOMAIN,
    path: '/',
    expires: dayjs().add(1, 'hour').toDate(),
  });
};
