import { atom } from 'recoil';
import { CampaignStatusKorean } from '@interfaces/campaigns';

interface campaignToChangeType {
  campaignName: string;
  campaignId: number;
  status: CampaignStatusKorean;
  isTestCampaign: boolean;
}

/** NOTE: 캠페인 statusModal에서 쓰이는 상태를 관리함 */
const campaignToChange = atom<campaignToChangeType | null>({
  key: 'campaignToChange',
  default: null,
});

export default campaignToChange;
