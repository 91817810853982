import { lazy } from 'react';
import { RouteType } from '@interfaces/routesType';

const LoginPage = lazy(() => import('@P/LoginPage'));
const CampaignsPage = lazy(() => import('@P/CampaignsPage'));
const ErrorPage = lazy(() => import('@P/ErrorPage'));
const MainPage = lazy(() => import('@P/MainPage'));

const routes: RouteType[] = [
  {
    path: '/',
    element: MainPage,
    accessibleBy: 'authorized',
  },
  {
    path: '/login',
    element: LoginPage,
    accessibleBy: 'unauthorized',
  },
  {
    path: '/campaigns',
    element: CampaignsPage,
    accessibleBy: 'authorized',
  },
  {
    path: '*',
    element: ErrorPage,
    accessibleBy: 'anyone',
  },
];

export default routes;
